<script setup>
import { storeToRefs } from "pinia";
import { useAnalyticsStore } from "~/store/useAnalyticsStore.js";

const useAnalytics = useAnalyticsStore();
const { filtersValues } = storeToRefs(useAnalytics);
const { removeFilter } = useAnalytics;

const onRemove = (event, filterValue) => {
  removeFilter(filterValue);
};
</script>

<template>
  <div v-if="filtersValues?.length" class="text-center">
    <Chip
      v-for="filter of filtersValues"
      :key="filter"
      :label="filter"
      class="mx-1 !text-sm my-1"
      removable
      @remove="onRemove($event, filter)"
    />
  </div>
</template>

<style></style>
