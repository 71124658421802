<script setup>
const props = defineProps({
  selectedDateRange: {
    type: Object,
    required: true,
    default: () => {
      return {
        start: "",
        end: "",
      };
    },
  },
});

const emit = defineEmits(["updateDateRange"]);

const dateValue = ref({
  startDate: props.selectedDateRange.start,
  endDate: props.selectedDateRange.end,
});

const formatter = ref({
  date: "DD MMM YYYY",
  month: "MMM",
});

const dDate = (date) => {
  return date > new Date();
};

watch(dateValue, () => {
  console.log("🚀 ~ watch ~ dateValue:", dateValue);
  emit("updateDateRange", dateValue.value);
});
</script>

<template>
  <vue-tailwind-datepicker
    :formatter="formatter"
    v-model="dateValue"
    :auto-apply="true"
    :disable-date="dDate"
    placeholder="Please select a date range"
    class="!w-64"
    separator=" to "
  />
</template>

<style lang="postcss">
.bg-vtd-primary-500 {
  @apply !bg-primary;
}
.text-vtd-primary-600, .text-vtd-primary-500 {
  @apply !text-primary;
}
</style>
